import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Modals, ModalService } from '../modal.service';

@Component({
  selector: 'app-vst-confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss'],
})
export class ConfirmActionModalComponent implements OnInit, OnDestroy {
  unsubscribeAll: Subject<void> = new Subject();

  contentKey?: string;

  callback?: (...args: any[]) => any;

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    this.modalService.showModal
      .pipe(
        takeUntil(this.unsubscribeAll),
        filter((m) => m.type === Modals.CONFIRM_ACTION),
      )
      .subscribe({
        next: (options) => {
          //@ts-ignore
          const modal = new bootstrap.Modal(
            document.getElementById('confirmActionModal') as Element,
          );

          modal.show();

          this.contentKey = options.data.contentKey;
          this.callback = options.callback;
        },
      });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  confirmAction() {
    if (this.callback) {
      this.callback(true);
    }
  }
}
