import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
/**
 * This guard rejects access to login and register pages if the user is already logged in.
 */
export class NoSessionGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    return this.doesntHaveSession();
  }

  canLoad() {
    return this.doesntHaveSession();
  }

  canActivateChild() {
    return this.doesntHaveSession();
  }

  doesntHaveSession() {
    const doesntHaveSession = this.authService.session.getValue() == null;

    if (!doesntHaveSession) {
      this.router.navigateByUrl('/');
    }

    return doesntHaveSession;
  }
}
