import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-vst-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  @ViewChild('toastElement', {
    static: true,
    read: ElementRef,
  })
  toastElement: ElementRef | undefined;

  @Input()
  type: 'error' | 'warning' | 'success' = 'error';

  @Input()
  contentKey: string = '';

  @Input()
  code: string | undefined = '';

  @Input()
  params?: { [key: string]: string };

  constructor() {}

  ngOnInit(): void {}
}
