import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'corsProxy',
  pure: true,
})
export class CorsProxyPipe implements PipeTransform {
  transform(value: string | null): string | null {
    // Add the cors proxy url prefix and API token

    if (value == null) {
      return null;
    }

    if (value.startsWith(`https://documentos.viajeporlasvoces.cl`)) {
      return value;
    }

    return environment.corsProxyUrl.concat(value, `?apikey=${environment.publicAnonKey}`);
  }
}
