import { NavigationItem } from './navigation-item.interface';

export const navigationItems: NavigationItem[] = [
  {
    title: 'Proyecto',
    color: '#de403b',
    imageUrl: 'assets/ui/boton-proyecto.png',
    url: 'info/proyecto',
  },
  {
    title: 'Actividades',
    color: '#fecb01',
    url: 'info/estudio',
    imageUrl: 'assets/ui/boton-estudio.png',
  },
  {
    title: 'Profesor/a',
    color: '#ff9833',
    url: 'profesores',
    imageUrl: 'assets/ui/boton-profesor.png',
  },
  {
    title: 'Estudiante',
    color: '#98cbcb',
    imageUrl: 'assets/ui/boton-estudiante.png',
    url: 'estudiantes',
  },
  {
    title: `Investigador/a`,
    color: `#f54281`,
    imageUrl: `assets/ui/boton-investigador.png`,
    url: `investigadores`,
  },
  {
    title: 'Contacto',
    color: '#679898',
    imageUrl: 'assets/ui/boton-contacto.png',
    url: 'info/contacto',
  },
];
