<div class="modal fade" id="confirmActionModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" translate>modals.confirmAction.title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="contentKey">
        {{contentKey | translate}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
          translate>modals.confirmAction.no</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="confirmAction()"
          translate>modals.confirmAction.yes</button>
      </div>
    </div>
  </div>
</div>