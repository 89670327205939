import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { backend } from 'src/app/common/backend/backend';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import { FavoritesService } from 'src/app/common/favorites/favorites.service';
import { DocumentWithMimeType } from 'src/app/common/interfaces/document-with-mime-type';
import FavoriteForJourney from 'src/app/common/models/FavoriteForJourney';
import { LogbookService } from 'src/app/logbook/logbook.service';
import { CanvasService } from '../../canvas.service';

export type FavouritedDocument = FavoriteForJourney & { document: DocumentWithMimeType };

@Component({
  selector: 'app-vst-document-favourites-carousel',
  templateUrl: './document-favourites-carousel.component.html',
  styleUrls: ['./document-favourites-carousel.component.scss'],
})
export class DocumentFavouritesCarouselComponent implements OnInit {
  favoriteDocuments: DocumentWithMimeType[] = [];
  answerDocuments: DocumentWithMimeType[] = [];

  showFetchingIndicator = false;

  unsubscribeAll$: Subject<void> = new Subject();

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private logbookService: LogbookService,
    private canvasService: CanvasService,
    private favoritesService: FavoritesService,
  ) {}

  ngOnInit(): void {
    this.fetchDocuments();

    this.initializeSubscriptions();
  }

  ngOnDestroy() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  initializeSubscriptions() {
    this.favoritesService.onDocumentFavorited.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: () => {
        // When the users add a new favorite, we fetch documents again

        this.fetchDocuments();
      },
    });
  }

  fetchDocuments() {
    this.showFetchingIndicator = true;

    Promise.all([
      backend
        .from<FavouritedDocument>('favorite_for_journey')
        .select('*, document(*, document_mime_type)')
        .eq('id_profile', this.authService.profile.getValue().id),
      backend.rpc<DocumentWithMimeType>('documents_for_answer_by_profile', {
        _id_profile: this.authService.profile.getValue().id,
      }),
    ]).then(([favouritedDocumentsResponse, answerDocumentsResponse]) => {
      this.showFetchingIndicator = false;

      if (favouritedDocumentsResponse.error) {
        this.toastService.showToast.emit({
          contentKey: 'error.requestError',
          code: 'backend.favourited-documents.errorFetching',
          type: 'error',
        });
        this.logbookService.showingLogbook.next(false);
        return;
      }

      if (answerDocumentsResponse.error) {
        this.toastService.showToast.emit({
          contentKey: 'error.requestError',
          code: 'backend.answer-documents.errorFetching',
          type: 'error',
        });
        this.logbookService.showingLogbook.next(false);
        return;
      }

      this.favoriteDocuments = favouritedDocumentsResponse.body.map((f) => f.document);
      this.answerDocuments = answerDocumentsResponse.body;
    });
  }

  addDocumentToCanvas(document: DocumentWithMimeType) {
    this.canvasService.addNewDocumentToCanvas.next(document);
  }
}
