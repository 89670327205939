import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { JourneyService } from 'src/app/journey/journey.service';
import { StageService } from 'src/app/journey/stage/stage.service';
import Stage from '../models/Stage';

@Component({
  selector: 'app-vst-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  unsubscribeAll$: Subject<void> = new Subject();

  collapseNavigationBar = true;

  smallerHeader = true;

  currentStage: Stage | null = null;

  constructor(
    private router: Router,
    private bpObserver: BreakpointObserver,
    public journeyService: JourneyService,
    private stageService: StageService,
  ) {
    this.router.events.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          this.collapseNavigationBar =
            !(
              event.url.startsWith('/info') ||
              event.url.startsWith('/registro') ||
              event.url.startsWith('/acceder') ||
              event.url === '' ||
              event.url === '/'
            ) || !bpObserver.isMatched('(min-width: 1000px)');

          this.smallerHeader = !(event.url.endsWith('trayecto') || event.url.endsWith('trayecto/'));
        }
      },
    });

    this.bpObserver
      .observe('(min-width: 1000px)')
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe({
        next: (value) => {
          this.collapseNavigationBar = !value.matches;
        },
      });

    this.stageService.currentStage.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (stage) => {
        this.currentStage = stage;
      },
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {}
}
