import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-vst-spinner-button',
  templateUrl: './spinner-button.component.html',
  styleUrls: ['./spinner-button.component.scss'],
})
export class SpinnerButtonComponent implements OnInit {
  @Output()
  onClick: EventEmitter<MouseEvent> = new EventEmitter();

  @Input()
  loading = false;

  @Input()
  buttonClass: string | null = null;

  @Input()
  disabled = false;

  showSuccessClass = false;

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {}

  showSuccess() {
    this.showSuccessClass = true;

    setTimeout(() => {
      this.ngZone.run(() => {
        this.showSuccessClass = false;
      });
    }, 3000);
  }
}
