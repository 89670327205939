<div #toastElement class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="d-flex">
    <div class="toast-body">
      <div class="d-flex align-items-center toast-icon">
        <ng-container [ngSwitch]="type">
          <i [class]="'toast-icon ' + type" *ngSwitchCase="'error'" class="fas fa-exclamation-circle"></i>
          <i [class]="'toast-icon ' + type" *ngSwitchCase="'warning'" class="fas fa-exclamation-triangle"></i>
          <i [class]="'toast-icon ' + type" *ngSwitchCase="'success'" class="fas fa-check-circle"></i>
        </ng-container>
        <div>
          {{contentKey | translate: params}}
          <div *ngIf="code" class="code-container">
            <strong><span translate>toast.code</span></strong><span>{{code}}</span>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>