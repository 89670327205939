import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { navigationItems } from '../navigation.items';
import Profile from '../../models/Profile';

@Component({
  selector: 'app-vst-navigation-drawer-content',
  templateUrl: './navigation-drawer-content.component.html',
  styleUrls: ['./navigation-drawer-content.component.scss'],
})
export class NavigationDrawerContentComponent implements OnInit, OnDestroy {
  navigationItems = navigationItems;

  profile?: Profile;

  unsubscribeAll: Subject<void> = new Subject();

  constructor(private authService: AuthService) {
    this.initializeSubscriptions();
  }

  initializeSubscriptions() {
    this.authService.profile.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (profile) => {
        this.profile = profile;

        if (profile) {
          this.navigationItems = navigationItems.filter((n) => n.url !== 'registro');
        } else {
          this.navigationItems = navigationItems;
        }
      },
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  requestLogout() {
    this.authService.logout();
  }
}
