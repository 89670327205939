import { LogbookTabItem } from './logbook-tab-item.interface';

export const logbookTabItems: LogbookTabItem[] = [
  {
    titleKey: 'logbook.tabs.answers',
    icon: 'fa-comment-dots',
    target: 'logbook-tab-answers',
  },
  {
    titleKey: 'logbook.tabs.canvas',
    icon: 'fa-th',
    target: 'logbook-tab-canvas',
  },
  {
    titleKey: 'logbook.tabs.writing',
    icon: 'fa-pen',
    target: 'logbook-tab-writing',
  },
];
