import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BackendTokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !request ||
      !request.url ||
      (request.url.startsWith('http') &&
        !(environment.apiUrl && request.url.startsWith(environment.apiUrl)))
    ) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        apikey: environment.publicAnonKey,
      },
    });
    return next.handle(request);
  }
}
