import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({
  name: 'safeHtml',
  pure: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value, type = 'url') {
    if (type === 'url') {
      return this.sanitizer.bypassSecurityTrustUrl(value);
    } else if (type === 'html') {
      const sanitizedValue = DOMPurify.sanitize(value);
      return this.sanitizer.bypassSecurityTrustHtml(sanitizedValue);
    }

    return null;
  }
}
