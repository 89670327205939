<div class="carousel-container d-flex flex-column" [@slideInFromRight]="{value: '', params: { duration: 500 }}"
  *ngIf="showingItemCarousel">
  <div class="close-arrow-container" (click)="hideCanvasItemCarousel()">
    <i class="fas fa-times"></i>
  </div>
  <div class="header">

    <h4 translate class="title">logbook.canvas.itemsCarousel.title</h4>
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a class="nav-link" [class.active]="currentTab === CanvasItemCarouselTabs.FAVOURITES"
          (click)="changeActiveTab(CanvasItemCarouselTabs.FAVOURITES)"
          translate>logbook.canvas.itemsCarousel.favorites</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [class.active]="currentTab === CanvasItemCarouselTabs.RESPONSES"
          (click)="changeActiveTab(CanvasItemCarouselTabs.RESPONSES)"
          translate>logbook.canvas.itemsCarousel.responses</a>
      </li>

      <li class="nav-item" *ngIf="canvasUrlForProfile != null">
        <a class="nav-link" [class.active]="currentTab === CanvasItemCarouselTabs.SHARE"
          (click)="changeActiveTab(CanvasItemCarouselTabs.SHARE)" translate>logbook.canvas.itemsCarousel.share</a>
      </li>
    </ul>
  </div>
  <div class="content">
    <div class="content-inner">
      <app-vst-document-favourites-carousel *ngIf="currentTab === CanvasItemCarouselTabs.FAVOURITES">
      </app-vst-document-favourites-carousel>
      <app-vst-answers-item-carousel *ngIf="currentTab === CanvasItemCarouselTabs.RESPONSES">
      </app-vst-answers-item-carousel>
      <div *ngIf="canvasUrlForProfile && currentTab === CanvasItemCarouselTabs.SHARE">
        <div class="mb-2" translate>logbook.canvas.itemsCarousel.shareHint</div>

        <a [href]="canvasUrlForProfile" target="_blank">{{canvasUrlForProfile}}</a>
      </div>
    </div>
  </div>
</div>