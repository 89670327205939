import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';
import { AuthService } from '../common/auth/auth.service';
import Question from '../common/models/Question';

@Injectable({
  providedIn: 'root',
})
export class LogbookService {
  selectedTabKey: BehaviorSubject<string> = new BehaviorSubject('logbook-tab-writing');

  relevantQuestions: BehaviorSubject<Question[]> = new BehaviorSubject([] as Question[]);

  showingLogbook: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showLogbookIcon: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(authService: AuthService) {
    // since this service gets instantiated before NgxWebStorage sets up the prefix
    // we use the browser API and add the prefix

    const storedValue = localStorage.getItem('v73|logbook-active') === 'true';

    authService.profile.pipe(skipWhile((v) => v == null)).subscribe({
      next: (profile) => {
        if (profile == null) {
          this.showingLogbook.next(false);
          return;
        }

        this.showingLogbook.next(storedValue == null ? false : storedValue);

        this.initializeSubscriptions();
      },
    });
  }

  initializeSubscriptions() {
    this.showingLogbook!.subscribe((showing) => {
      localStorage.setItem('v73|logbook-active', showing.toString());
    });
  }
}
