import { EventEmitter, Injectable } from '@angular/core';
import { from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { backend } from '../backend/backend';
import FavoriteForJourney from '../models/FavoriteForJourney';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  /**
   * Event that fires once a document gets favorited, so any component can refresh
   */
  onDocumentFavorited: EventEmitter<void> = new EventEmitter();

  constructor(private authService: AuthService) {}

  addDocumentToJourneyFavorites(journeyId: string, documentId: string) {
    return from(
      backend.from<FavoriteForJourney>('favorite_for_journey').insert({
        id_document: documentId,
        id_journey: journeyId,
        id_profile: this.authService.profile.getValue()!.id,
      }),
    ).pipe(
      tap((response) => {
        if (!response.error) {
          this.onDocumentFavorited.emit();
        }
      }),
    );
  }

  deleteDocumentFromJourneyFavorites(journeyId: string, documentId: string) {
    return from(
      backend
        .from<FavoriteForJourney>('favorite_for_journey')
        .delete()
        .eq('id_document', documentId)
        .eq('id_journey', journeyId)
        .eq('id_profile', this.authService.profile.getValue()!.id),
    ).pipe(
      tap((response) => {
        if (!response.error) {
          this.onDocumentFavorited.emit();
        }
      }),
    );
  }
}
