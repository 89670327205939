import { Component, Input, OnInit } from '@angular/core';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { CanvasItem } from '../canvas.component';

@Component({
  selector: 'app-vst-canvas-content',
  templateUrl: './canvas-content.component.html',
  styleUrls: ['./canvas-content.component.scss'],
})
export class CanvasContentComponent implements OnInit {
  @Input()
  options!: GridsterConfig;

  @Input()
  canvasItems!: CanvasItem[];

  constructor() {}

  ngOnInit(): void {}

  itemDashboardTrackByFn(index: number, b: GridsterItem) {
    return b.id;
  }
}
