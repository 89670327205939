import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GridsterConfig, GridsterItem } from 'angular-gridster2';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { backend } from 'src/app/common/backend/backend';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import Canvas from 'src/app/common/interfaces/Canvas';
import { LogbookService } from '../logbook.service';
import { CanvasService } from './canvas.service';

export interface CanvasItem extends GridsterItem {}

@Component({
  selector: 'app-vst-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit, OnDestroy {
  requestCanvasPersistence: EventEmitter<void> = new EventEmitter();

  options: GridsterConfig = {
    gridType: 'scrollVertical',
    resizable: {
      enabled: true,
    },
    draggable: {
      enabled: true,
    },
    minCols: 10,
    minRows: 5,
    maxRows: 10000,
    maxCols: 10000,
    rowHeightRatio: 0.1,
    swap: true,
    swapWhileDragging: true,
    itemInitCallback: () => {
      this.requestCanvasPersistence.emit();
    },
    itemChangeCallback: () => {
      this.requestCanvasPersistence.emit();
    },
  };

  /**
   * Canvas's items. Parsed from a JSON stored in postgres
   */
  canvasItems: CanvasItem[] = [];

  isLoading: boolean = false;

  unsubscribeAll$ = new Subject();

  constructor(
    private logbookService: LogbookService,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private canvasService: CanvasService,
  ) {}

  ngOnInit(): void {
    this.initializeCanvas();
  }

  ngOnDestroy() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  initializeCanvas() {
    backend
      .from<Canvas>('canvas')
      .select('*')
      .eq('id_profile', this.authService.profile.getValue().id)
      .then((canvasResponse) => {
        if (canvasResponse.error) {
          this.toastService.showToast.emit({
            contentKey: 'error.requestError',
            code: 'backend.canvas.errorFetching',
            type: 'error',
          });
          this.logbookService.showingLogbook.next(false);
          return;
        }

        this.canvasItems =
          canvasResponse.body.length === 1 ? JSON.parse(canvasResponse.body[0].items ?? '[]') : []; // Fallback value

        this.initializeSubscriptions();
      });
  }

  initializeSubscriptions() {
    this.requestCanvasPersistence
      .pipe(takeUntil(this.unsubscribeAll$), debounceTime(300))
      .subscribe({
        next: () => {
          backend
            .from<Canvas>('canvas')
            .upsert(
              {
                items: JSON.stringify(this.canvasItems),
                id_profile: this.authService.profile.getValue().id,
              },
              {
                onConflict: 'id_profile',
              },
            )
            .then((updateResponse) => {
              if (updateResponse.error) {
                this.toastService.showToast.emit({
                  contentKey: 'logbook.canvas.errors.onUpdate',
                  code: 'backend.canvas.errorFetching',
                  type: 'error',
                });
                this.router.navigateByUrl('/');
                return;
              }

              this.canvasItems = JSON.parse(updateResponse.body[0].items!);
            });
        },
      });

    this.canvasService.addNewDocumentToCanvas.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (document) => {
        this.canvasItems.push({
          document,
          kind: 'document',
          cols: 1,
          rows: 6,
          x: 0,
          y: 0,
        });

        this.requestCanvasPersistence.emit();
      },
    });

    this.canvasService.addNewAnswerCardToCanvas.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (answerAndQuestion) => {
        this.canvasItems.push({
          answer: answerAndQuestion,
          kind: 'answer',
          cols: 4,
          rows: 3,
          x: 0,
          y: 0,
        });

        this.requestCanvasPersistence.emit();
      },
    });
  }
}
