import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/common/auth/auth.service';
import { backend } from 'src/app/common/backend/backend';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import Answer from 'src/app/common/models/Answer';
import Question from 'src/app/common/models/Question';
import { LogbookService } from 'src/app/logbook/logbook.service';
import { CanvasService } from '../../canvas.service';

export type AnswerAndQuestion = Answer & { question: Question };

@Component({
  selector: 'app-vst-answers-item-carousel',
  templateUrl: './answers-item-carousel.component.html',
  styleUrls: ['./answers-item-carousel.component.scss'],
})
export class AnswersItemCarouselComponent implements OnInit {
  showFetchingIndicator = false;

  answersAndQuestions: AnswerAndQuestion[] = [];

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private logbookService: LogbookService,
    private canvasService: CanvasService,
  ) {}

  ngOnInit(): void {
    this.fetchAnswers();
  }

  fetchAnswers() {
    backend
      .from<AnswerAndQuestion>('answer')
      .select('*, question(*)')
      .eq('id_profile', this.authService.profile.getValue().id)
      .then((answerAndQuestionResponse) => {
        if (answerAndQuestionResponse.error) {
          this.toastService.showToast.emit({
            contentKey: 'error.requestError',
            code: 'backend.responses.errorFetching',
            type: 'error',
          });
          this.logbookService.showingLogbook.next(false);
          return;
        }

        this.answersAndQuestions = answerAndQuestionResponse.body;
      });
  }

  addAnswerToCanvas(answerAndQuestion: AnswerAndQuestion) {
    this.canvasService.addNewAnswerCardToCanvas.emit(answerAndQuestion);
  }
}
