import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { backend } from 'src/app/common/backend/backend';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import Canvas from 'src/app/common/interfaces/Canvas';
import { environment } from 'src/environments/environment';
import { LogbookService } from '../../logbook.service';
import { CanvasService } from '../canvas.service';

export enum CanvasItemCarouselTabs {
  FAVOURITES,
  RESPONSES,
  SHARE,
}

@Component({
  selector: 'app-vst-canvas-item-carousel',
  templateUrl: './canvas-item-carousel.component.html',
  styleUrls: ['./canvas-item-carousel.component.scss'],
  animations: [
    trigger('slideInFromRight', [
      state(
        'void',
        style({
          transform: 'translateX(100vw)',
        }),
      ),
      state(
        '*',
        style({
          transform: 'translateX(0)',
        }),
      ),
      transition('void <=> *', animate('{{duration}}ms {{delay}}ms ease-in'), {
        params: {
          duration: 300,
          delay: 0,
        },
      }),
    ]),
  ],
})
export class CanvasItemCarouselComponent implements OnInit, OnDestroy {
  CanvasItemCarouselTabs = CanvasItemCarouselTabs;

  currentTab: CanvasItemCarouselTabs = CanvasItemCarouselTabs.FAVOURITES;

  showingItemCarousel = false;

  unsubscribeAll$: Subject<void> = new Subject<void>();

  canvasUrlForProfile?: string;

  constructor(
    private canvasService: CanvasService,
    private authService: AuthService,
    private logbookService: LogbookService,
    private toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.fetchCanvasId();
  }

  fetchCanvasId() {
    backend
      .from<Canvas>('canvas')
      .select('*')
      .eq('id_profile', this.authService.profile.getValue().id)
      .then((response) => {
        if (response.error) {
          this.toastService.showToast.emit({
            contentKey: 'logbook.writing.errorOnFetch',
            code: 'logbook.canvas.error-fetching-canvas-id',
            type: 'error',
          });

          this.logbookService.showingLogbook.next(false);
          return;
        }

        if (response.body != null && response.body.length > 0) {
          this.canvasUrlForProfile = `${environment.siteUrl}/ver-lienzo/${response.body[0].id}`;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  initializeSubscriptions() {
    this.canvasService.showingItemCarousel.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (showingItemCarousel) => {
        this.showingItemCarousel = showingItemCarousel;
      },
    });
  }

  changeActiveTab(tab: CanvasItemCarouselTabs) {
    this.currentTab = tab;
  }

  hideCanvasItemCarousel() {
    this.canvasService.showingItemCarousel.next(false);
  }
}
