<section class="answers-container" *ngIf="currentExperience">
  <div *ngIf="savingAnswer" class="spinner-border text-warning" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div class="answers-scrollable-content">
    <div *ngIf="currentExperience.n_docs_required && currentExperience.n_docs_required > selectedDocuments.length ">
      <i class="fas fa-info-circle"></i>
      <span translate [translateParams]="{number: currentExperience.n_docs_required}">
        logbook.answers.numberDocumentsRequired
      </span>
    </div>

    <ng-container *ngIf="answersFormGroup">
      <ng-container *ngIf="!questionsApplyToAllDocuments; else questionsApplyToAllDocumentsAnswersContainer">
        <ng-container *ngFor="let document of selectedDocuments">
          <div *ngIf="selectedDocuments.length > 0" class="document-answers-container">
            <strong class="selected-documents-hint" translate>logbook.answers.answeringForThisDocument</strong>
            <div class="document-selection-container d-flex flex-row w-100 mt-3">
              <div class="card document-card d-flex flex-row">
                <div class="thumbnail-container">
                  <img onError="this.src='/assets/ui/no-thumbnail.png'" [src]="document.thumbnail_url | corsProxy">
                </div>

                <div class="d-flex flex-wrap flex-column ms-4">
                  <span *ngIf="document.title != null"><strong class="me-1"
                      translate>documentCarousel.title</strong>{{document.title}}</span>
                  <span *ngIf="document.dated != null"><strong class="me-1"
                      translate>documentCarousel.dated</strong>{{document.dated}}</span>
                  <span *ngIf="document.author != null"><strong class="me-1"
                      translate>documentCarousel.author</strong>{{document.author}}</span>
                  <span *ngIf="document.location != null"><strong class="me-1"
                      translate>documentCarousel.location</strong>{{document.location}}</span>
                </div>
              </div>
            </div>
          </div>
          <ng-container [formGroup]="$any(answersFormGroup.controls[document.id])">
            <ng-container *ngFor="let question of questions">
              <div class="question-container mt-4" *ngIf="!question.is_general">

                <span class="question-prompt">{{question.content}}</span>
                <textarea [ngClass]="question.textarea_size == null ? 'medium' : question.textarea_size"
                  [style.background]="currentStage != null && currentStage.color != null ? 'rgba' + currentStage.color.slice(3, -1) + ', 0.5)' : 'rgb(207, 243, 238) !important'"
                  (input)="onTextAreaChange()" class="form-control mt-3" [formControlName]="question.id"></textarea>
              </div>
            </ng-container>

          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #questionsApplyToAllDocumentsAnswersContainer>
        <ng-container [formGroup]="answersFormGroup">
          <ng-container *ngFor="let question of questions">
            <div class="question-container mt-4" *ngIf="!question.is_general">
              <span class="question-prompt">{{question.content}}</span>
              <textarea [ngClass]="question.textarea_size == null ? 'medium' : question.textarea_size"
                [style.background]="currentStage != null && currentStage.color != null ? 'rgba' + currentStage.color.slice(3, -1) + ', 0.2)' : 'rgb(207, 243, 238) !important'"
                (input)="onTextAreaChange()" class="form-control mt-3 answer-container"
                [formControlName]="question.id"></textarea>
            </div>
          </ng-container>

        </ng-container>
      </ng-template>

    </ng-container>
    <ng-container *ngIf="answersFormGroup">
      <ng-container *ngFor="let question of questions" [formGroup]="answersFormGroup">
        <ng-container *ngIf="question.is_general">
          <div class="mt-4"><strong translate>logbook.answers.generalQuestion</strong></div>
          <div class="question-container mt-1">
            <span class="question-prompt">{{question.content}}</span>
            <textarea [ngClass]="question.textarea_size == null ? 'medium' : question.textarea_size"
              [style.background]="currentStage != null && currentStage.color != null ? 'rgba' + currentStage.color.slice(3, -1) + ', 0.2)' : 'rgb(207, 243, 238) !important'"
              (input)="onTextAreaChange()" class="form-control mt-3 answer-container"
              [formControlName]="question.id"></textarea>
          </div>
        </ng-container>

      </ng-container>
      <div class="d-flex flex-md-row flex-column justify-content-end mt-4 gap-2"
        *ngIf="answersFormGroup && currentExperience && (selectedDocuments || questionsApplyToAllDocuments)">
        <app-vst-spinner-button #confirmButton class="confirm-button"
          [disabled]="answersFormGroup.invalid ||  (currentExperience!.n_docs_required! > selectedDocuments!.length && !questionsApplyToAllDocuments)"
          buttonClass="btn btn-info" (onClick)="saveAnswers(confirmButton, false)">
          {{ 'logbook.answers.sendButton' | translate }}
        </app-vst-spinner-button>
        <app-vst-spinner-button #confirmButtonAndNext class="confirm-button"
          [disabled]="answersFormGroup.invalid ||  (currentExperience!.n_docs_required! > selectedDocuments!.length && !questionsApplyToAllDocuments)"
          buttonClass="btn btn-primary send-button-next" (onClick)="saveAnswers(confirmButtonAndNext, true)">
          {{ 'logbook.answers.sendButtonAndNext' | translate }}
        </app-vst-spinner-button>
      </div>
    </ng-container>

  </div>
</section>