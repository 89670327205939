import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import Profile from '../models/Profile';
import { navigationItems } from './navigation.items';

@Component({
  selector: 'app-vst-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  navigationItems = navigationItems;

  unsubscribeAll: Subject<void> = new Subject();

  profile?: Profile;

  constructor(private authService: AuthService) {
    this.initializeSubscriptions();
  }

  ngOnInit(): void {}

  initializeSubscriptions() {
    this.authService.profile.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (profile) => {
        this.profile = profile;

        if (profile) {
          this.navigationItems = navigationItems.filter((n) => n.url !== 'registro');

          if (profile.type === 'teacher') {
            navigationItems.find((ni) => ni.url == 'estudiantes')!.title = 'Trayecto';
          } else {
            navigationItems.find((ni) => ni.url == 'estudiantes')!.title = 'Estudiantes';
          }
        } else {
          this.navigationItems = navigationItems;
          navigationItems.find((ni) => ni.url == 'estudiantes')!.title = 'Estudiantes';
        }
      },
    });
  }

  requestLogout() {
    this.authService.logout();
  }
}
