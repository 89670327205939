import * as dayjs from 'dayjs';
import Answer from '../models/Answer';

export const getLatestTimestampForAnswerArray = (answers: Answer[]) => {
  if (answers.length == 0) {
    return null;
  }

  return answers.reduce<dayjs.Dayjs | null>((highest, curr) => {
    if (highest == null || dayjs(curr.updated_at).isAfter(highest)) {
      return dayjs(curr.updated_at).utc();
    }

    return highest;
  }, null);
};
