<ngx-loading-bar color="#ffca02" height="7px" [includeSpinner]="false"></ngx-loading-bar>
<div class="toasts-container d-flex flex-column align-items-center">
  <div #toastsContainer></div>
</div>
<div class="logbook-overlay-container" *ngIf="showingLogbook" [style.opacity]="logbookOpacity">
  <div class="logbook-slider-container" [class.full-height]="fullHeightLogbook"
    [@slideInRightOnEnter]="{value:'', params: {delay: 0, duration: 500}}">
    <div class="d-flex p-1 p-md-3 align-items-center title justify-content-between">
      <div>Bitácora</div>
      <!--
<div class="logbook-opacity-toggler" (mouseenter)="setLogbookOpacity(0.1)" (mouseleave)="setLogbookOpacity(1)">
        <span class="fas fa-eye"></span></div>
      -->

      <button type="button" class="btn-close" (click)="hideLogbook()"></button>
    </div>
    <app-vst-logbook></app-vst-logbook>
  </div>
</div>
<div [class.animate-dark]="animateDarkBackground" [class.dark]="darkBackground"
  class="app-container  container-fluid d-flex flex-column flex-grow-0 flex-shrink-0">

  <app-vst-header></app-vst-header>
  <div class="outlet-row">
    <router-outlet></router-outlet>

  </div>
</div>
<div class="logbook-icon" *ngIf="showLogbookButton" [@slideInUpOnEnter]="{ value:'', params: {delay: 0}}"
  (click)="showLogbook()">
  <i class="fas fa-book">
  </i>
  <span class="text" translate>logbook.title</span>

</div>
<app-vst-confirm-action-modal></app-vst-confirm-action-modal>