<div class="favourites-carousel-container d-flex flex-column justify-content-center">
  <div class="spinner spinner-border text-primary" role="status" *ngIf="showFetchingIndicator">
    <span class="sr-only">Loading...</span>
  </div>

  <div translate class="muted favorites-carousel-header mt-1 mb-3">logbook.canvas.itemsCarousel.favoritesHeader</div>

  <ng-container *ngFor="let favorite of favoriteDocuments">
    <ng-container *ngTemplateOutlet="documentTemplate; context: {document: favorite}"></ng-container>
  </ng-container>

  <ng-container *ngFor="let document of answerDocuments">
    <ng-container *ngTemplateOutlet="documentTemplate; context: {document: document}"></ng-container>
  </ng-container>

</div>

<ng-template #documentTemplate let-document="document">
  <div class="favorite-document-item mb-3" [ngSwitch]="document.document_mime_type">
    <div (click)="addDocumentToCanvas(document)"><i
        class="add-to-canvas-button text-primary fas fa-arrow-circle-left"></i></div>

    <ng-container *ngSwitchCase="'image'">
      <img class="image-document" onError="this.src='/assets/ui/no-thumbnail.png'"
        [src]="document.thumbnail_url | corsProxy">
    </ng-container>
    <ng-container *ngSwitchCase="'audio'">
      <audio class="audio-document" controls controlsList="nodownload" [src]="document.url | corsProxy"></audio>
    </ng-container>
    <ng-container *ngSwitchCase="'text'">
      <div class="html-document-container" [innerHTML]="document.url | safeHtml: 'html'"
        *ngIf="document.mime === 'text/html'"></div>
    </ng-container>
    <ng-container *ngSwitchCase="'video'">
      <img class="image-document" onError="this.src='/assets/ui/no-thumbnail.png'"
        [src]="document.thumbnail_url | corsProxy">
    </ng-container>
    <ng-container *ngSwitchCase="'application'">
      <img class="image-document" onError="this.src='/assets/ui/no-thumbnail.png'"
        [src]="document.thumbnail_url | corsProxy">
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img class="image-document" src="/assets/ui/no-thumbnail.png">
    </ng-container>
  </div>
</ng-template>