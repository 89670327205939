<div class="moneda-cover">
  <div class="moneda-image-container">
    <img waitableImage #monedaCoverImage alt="La Moneda de día" class="moneda-image" [@fadeInOut]="imagesState"
      src="assets/illustrations/moneda_dia.png">
    <ng-container *ngIf="monedaCoverImagePositionAndSize != null">
      <img class="moneda-flower" [@fadeInOut]="{value: imagesState, params: {delay: 1000}}" waitableImage
        src="assets/illustrations/moneda-floreada/anunuca-techo.png" [style]="{
        'height': (monedaCoverImagePositionAndSize.height * 0.2) + 'px',
        'max-height': '150px',
        position: 'absolute',
        top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.81)) + 'px - 2%)',
        right: '34%'}">
      <img class="moneda-flower" [@fadeInOut]="{value: imagesState, params: {delay: 1000}}" waitableImage
        src="assets/illustrations/moneda-floreada/anunuca-techo.png" [style]="{
      'height': (monedaCoverImagePositionAndSize.height * 0.2) + 'px',
      'max-height': '150px',
      position: 'absolute',
      top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.81)) + 'px - 2%)',
      left: '32%'}">
      <img class="moneda-flower" [@fadeInOut]="{value: imagesState, params: {delay: 6000}}" waitableImage
        src="assets/illustrations/moneda-floreada/flor-techo-moneda.png" [style]="{
        'height': (monedaCoverImagePositionAndSize.height * 0.1) + 'px',
        'max-height': '150px',
        position: 'absolute',
        top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.71)) + 'px - 2%)',
        right: '28%'}">
      <img class="moneda-flower" [@fadeInOut]="{value: imagesState, params: {delay: 6000}}" waitableImage
        src="assets/illustrations/moneda-floreada/flor-techo-moneda.png" [style]="{
      'height': (monedaCoverImagePositionAndSize.height * 0.1) + 'px',
      'max-height': '150px',
      position: 'absolute',
      top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.71)) + 'px - 2%)',
      left: '26%'}">
      <img [@fadeInOut]="{value: imagesState, params: {delay: 5000}}" class="moneda-flower" waitableImage
        src="assets/illustrations/moneda-floreada/flor-esquina-moneda-derecha.png" [style]="{
      'height': (monedaCoverImagePositionAndSize.height * 0.8) + 'px',
      'max-height': '300px',
      position: 'absolute',
      top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.86)) + 'px - 2%)',
      right: '9%'}">
      <img [@fadeInOut]="{value: imagesState, params: {delay: 5000}}" class="moneda-flower" waitableImage
        src="assets/illustrations/moneda-floreada/flor-esquina-moneda-izquierda.png" [style]="{
      'height': (monedaCoverImagePositionAndSize.height * 0.8) + 'px',
      'max-height': '300px',
      position: 'absolute',
      top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.86)) + 'px - 2%)',
      left: '7%'}">
      <img [@fadeInOut]="{value: imagesState, params: {delay: 7000}}" class="moneda-flower" waitableImage
        src="assets/illustrations/moneda-floreada/flor-lateral-moneda-izquierda.png" [style]="{
        'height': (monedaCoverImagePositionAndSize.height * 0.1) + 'px',
        'max-height': '150px',
        position: 'absolute',
        top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.3)) + 'px - 2%)',
        left: '12%'}">
      <img [@fadeInOut]="{value: imagesState, params: {delay: 7000}}" class="moneda-flower" waitableImage
        src="assets/illustrations/moneda-floreada/flor-lateral-moneda-derecha.png" [style]="{
      'height': (monedaCoverImagePositionAndSize.height * 0.1) + 'px',
      'max-height': '150px',
      position: 'absolute',
      top: 'calc(' + (monedaCoverImagePositionAndSize.offsetBottom - (monedaCoverImagePositionAndSize.height * 0.3)) + 'px - 2%)',
      right: '14%'}">
    </ng-container>
  </div>
</div>
<div class="bottom-flowers">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 3000}}" [style.right]="'0px'"
    src="assets/illustrations/moneda-floreada/anunucas_base.png">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 4000}}" [style.right]="'0px'"
    src="assets/illustrations/moneda-floreada/flores-moradas-base.png">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 3000}}"
    src="assets/illustrations/moneda-floreada/anunucas_base.png">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 4000}}" [style.left]="'0px'"
    src="assets/illustrations/moneda-floreada/flores-moradas-base.png">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 4000}}" [style.left]="'18%'"
    src="assets/illustrations/moneda-floreada/flores-base-azul.png">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 4000}}" [style.left]="'40%'"
    src="assets/illustrations/moneda-floreada/flores-base-azul.png">
  <img waitableImage [@fadeInOut]="{value: imagesState, params: {delay: 2000}}" [style.max-height]="'230px'"
    [style.left]="'45%'" src="assets/illustrations/moneda-floreada/anunuca-central.png">
</div>