<header class="d-flex align-items-center header-container flex-row">
    <div class="standard-header header-title-container me-2"
        *ngIf="(journeyService.currentJourney | async) == null; else journeyHeader" [routerLink]="'/'">
        <h1 class="header-title">VIAJE POR LAS VOCES</h1>
        <h1 class="header-title">DE LA DICTADURA <span class="lighter">(1973-1990)</span></h1>
    </div>
    <div [style.display]="collapseNavigationBar ? 'flex' : 'none'" data-bs-toggle="offcanvas"
        data-bs-target="#navigationOffCanvas" class="flex-column align-content-center justify-content-center
         flex-grow  navigation-drawer-button-container">
        <img src="assets/ui/menu.png">
        <div>
            Menú
        </div>
    </div>
    <div *ngIf="!collapseNavigationBar" class="flex-row navigation">
        <app-vst-navigation></app-vst-navigation>
    </div>
</header>
<div class="offcanvas offcanvas-end " data-bs-backdrop="false" tabindex="-1" id="navigationOffCanvas"
    aria-labelledby="offcanvasLabel">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">Navegación</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

    </div>
    <div class="offcanvas-body">
        <app-vst-navigation-drawer-content></app-vst-navigation-drawer-content>
    </div>
</div>

<ng-template #journeyHeader>
    <div [class.minimal]="currentStage != null" *ngIf="currentStage != null; else journeyOverviewHeader">
        <div class="journey-header-flowholder d-none d-md-block minimal">
        </div>
        <div class="journey-header minimal d-flex flex-column stage-header">
            <div class="site-title minimal" [routerLink]="'/'">VIAJE POR LAS VOCES DE LA DICTADURA <span
                    class="lighter">(1973-1990)</span></div>
            <div class="stage-name"
                [style.background-color]="currentStage.color != null ? currentStage.color : 'rgb(255, 202, 0)'">
                {{currentStage.ordinal}}.
                {{currentStage.name}}</div>
        </div>
    </div>

    <ng-template #journeyOverviewHeader>
        <div class="journey-header-flowholder d-none d-md-block" [class.smaller]="smallerHeader">
        </div>
        <div [class.smaller]="smallerHeader" class="journey-header map-header"
            *ngIf="(journeyService.currentJourney | async) as journey">
            <div class="journey-name" [routerLink]="'/trayectos/' + journey.codename">{{journey.name}}</div>
            <div class="site-title mt-1" [routerLink]="'/'">VIAJE POR LAS VOCES DE LA DICTADURA <span
                    class="lighter">(1973-1990)</span></div>
            <div [class.smaller]="smallerHeader" class="journey-slogan mt-1">{{journey.slogan}}</div>
        </div>
    </ng-template>

</ng-template>