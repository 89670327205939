<gridster [options]="options" *ngIf="canvasItems != null && canvasItems.length > 0">
  <gridster-item [item]="item"
    *ngFor="let item of canvasItems; trackBy: itemDashboardTrackByFn; let itemIndex = index; ">
    <div class="gridster-item-inner">

      <div *ngIf="item.kind === 'document'" class="favorite-document" [ngSwitch]="item.document.document_mime_type">
        <ng-container *ngSwitchCase="'image'">
          <img class="image-document" [src]="item.document.url | corsProxy">
        </ng-container>
        <ng-container *ngSwitchCase="'audio'" swiperSlide>
          <audio class="audio-document" controls controlsList="nodownload" [src]="item.document.url | corsProxy"></audio>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <div class="html-document-container" [innerHTML]="item.document.url | safeHtml: 'html'"
            *ngIf="item.document.mime === 'text/html'"></div>
        </ng-container>
        <ng-container *ngSwitchCase="'video'" swiperSlide>
          <video preload="auto" class="video-document" [vgMedia]="$any(media)" #media id="singleVideo" controls>
            <source [src]="item.document.url | corsProxy" [type]="item.document.mime">
          </video>
        </ng-container>
        <ng-container *ngSwitchCase="'application'" swiperSlide>
          <pdf-viewer [autoresize]="true" [render-text]="false" [original-size]="false"
            style="display: flex; width: 100%; height: 100%; max-width: 100%; max-height: 100%;" class="pdf-document"
            [page]="1" [src]="(item.document.url | corsProxy)!" [render-text]="false">
          </pdf-viewer>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <img class="image-document" src="/assets/ui/no-thumbnail.png">
        </ng-container>
      </div>

      <div *ngIf="item.kind === 'answer'" class="answer-card mt-2">
        <div><strong>{{item.answer.question.content}}</strong></div>

        <div>
          {{item.answer.answer_text}}
        </div>
      </div>
    </div>
  </gridster-item>

</gridster>