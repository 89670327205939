import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  rollInOnEnterAnimation,
  slideInRightOnEnterAnimation,
  slideInUpOnEnterAnimation,
  slideOutDownOnLeaveAnimation,
  slideOutRightOnLeaveAnimation,
} from 'angular-animations';
import 'bootstrap';
import * as bootstrap from 'bootstrap';
import { LocalStorage } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from './common/auth/auth.service';
import { ToastComponent } from './common/components/toast/toast.component';
import { ToastService } from './common/components/toast/toast.service';
import { ImageLoadingService } from './common/image-loading.service';
import { UiService } from './common/ui.service';
import { LogbookService } from './logbook/logbook.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    rollInOnEnterAnimation(),
    slideInUpOnEnterAnimation(),
    slideOutDownOnLeaveAnimation(),
    slideInRightOnEnterAnimation(),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('toastsContainer', { read: ViewContainerRef })
  toastsContainer: ViewContainerRef | null = null;

  @LocalStorage('selectedLanguage', 'es')
  selectedLanguage: string = 'es';

  unsubscribeAll: Subject<void> = new Subject();

  showingLogbook = false;

  showLogbookButton: boolean = false;

  fullHeightLogbook = false;

  logbookOpacity = 1;

  darkBackground = false;
  animateDarkBackground = false;

  constructor(
    translateService: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private toastService: ToastService,
    private logbookService: LogbookService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private imageService: ImageLoadingService,
    private uiService: UiService,
  ) {
    this.uiService.inititateLandingAnimation.subscribe(() => {
      setTimeout(() => {
        this.darkBackground = true;
      });

      const transitionSubscription = this.uiService.initiateLandingAnimationTransition.subscribe({
        next: () => {
          this.animateDarkBackground = true;

          setTimeout(() => {
            this.darkBackground = false;
          });

          setTimeout(() => {
            this.animateDarkBackground = false;
          }, 3000);

          transitionSubscription.unsubscribe();
        },
      });
    });

    // we setup language settings

    translateService.setDefaultLang(this.selectedLanguage);
    translateService.use(this.selectedLanguage);

    this.initializeToasts();
    this.initializeSubscriptions();
  }

  initializeSubscriptions() {
    this.logbookService.showingLogbook
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((showingLogbook) => {
        this.showingLogbook = showingLogbook && this.authService.profile != null;
      });

    this.authService.profile.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (profile) => {
        this.showLogbookButton =
          profile != null && this.logbookService.showLogbookIcon.getValue() === true;
      },
    });

    this.logbookService.showLogbookIcon.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (value) => {
        this.showLogbookButton = value;
      },
    });

    this.logbookService.selectedTabKey.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (tab) => {
        this.fullHeightLogbook = tab === 'logbook-tab-canvas';
      },
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  initializeToasts() {
    // Shows toasts when required

    this.toastService.showToast.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (toastProperties) => {
        const componentFactory =
          this.componentFactoryResolver.resolveComponentFactory(ToastComponent);
        const component = this.toastsContainer!.createComponent(componentFactory);

        component.instance.code = toastProperties.code;
        component.instance.contentKey = toastProperties.contentKey;
        component.instance.type = toastProperties.type;
        component.instance.params = toastProperties.params;

        setTimeout(() => {
          // Wait one change detection cycle

          new bootstrap.Toast(component.instance.toastElement?.nativeElement, {
            animation: true,
            autohide: true,
          }).show();
        });

        setTimeout(() => {
          component.destroy();
        }, 5500); // delay + 5 seconds = destroy
      },
    });
  }

  showLogbook() {
    this.logbookService.showingLogbook.next(true);
  }

  hideLogbook() {
    this.logbookService.showingLogbook.next(false);
  }

  setLogbookOpacity(value: number) {
    this.logbookOpacity = value;
  }
}
