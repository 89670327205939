<div class="writing-tab-container w-100">
  <div class="writing-tab-content ps-4 pe-4 pt-2">
    <div class="spinner-border text-primary loading-spinner" role="status" *ngIf="showLoadingSpinner">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="form-group">
      <label class="pb-2" translate>logbook.writing.label</label>
      <quill-editor [formControl]="textAreaFormControl"
        [placeholder]="'Ideas, reflexiones, pensamientos, sensaciones...'" class="h-100 w-100"></quill-editor>
    </div>
  </div>
</div>