import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { backend } from 'src/app/common/backend/backend';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import Profile from 'src/app/common/models/Profile';
import { LogbookService } from '../logbook.service';

@Component({
  selector: 'app-vst-writing',
  templateUrl: './writing.component.html',
  styleUrls: ['./writing.component.scss'],
})
export class WritingComponent implements OnInit {
  unsubscribeAll$: Subject<void> = new Subject();

  textAreaFormControl: FormControl = new FormControl(null);

  showLoadingSpinner = false;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private logbookService: LogbookService,
  ) {
    this.initializeSubscriptions();
  }

  initializeSubscriptions() {
    this.textAreaFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribeAll$), debounceTime(300))
      .subscribe({
        next: () => {
          this.requestPersistence();
        },
      });

    this.restoreWritingValue();
  }

  restoreWritingValue() {
    this.showLoadingSpinner = true;
    const profile: Profile = this.authService.profile.getValue();

    if (profile == null) {
      this.toastService.showToast.emit({
        contentKey: 'logbook.writing.errorOnFetch',
        type: 'error',
        code: 'backend.experience.errorFetching',
      });

      this.logbookService.showingLogbook.next(false);

      return;
    }

    backend
      .from<Profile>('profile')
      .select('logbook_writing_content')
      .eq('id', profile.id)
      .then((response) => {
        if (response.error && response.body == null) {
          this.toastService.showToast.emit({
            contentKey: 'logbook.writing.errorOnFetch',
            type: 'error',
            code: 'backend.experience.errorFetching',
          });

          this.logbookService.showingLogbook.next(false);

          return;
        }

        this.showLoadingSpinner = false;
        this.textAreaFormControl.setValue(response.body[0].logbook_writing_content, {
          emitEvent: false,
        });
      });
  }

  requestPersistence() {
    const currentValue = this.textAreaFormControl.value;

    const profile: Profile = this.authService.profile.getValue();

    if (profile != null) {
      backend
        .from<Profile>('profile')
        .update({
          logbook_writing_content: currentValue,
        })
        .eq('id', profile.id)
        .then((response) => {
          if (response.error) {
            this.toastService.showToast.emit({
              contentKey: 'logbook.writing.errorOnSave',
              type: 'error',
              code: 'logbook.writing.errorSaving',
            });

            this.logbookService.showingLogbook.next(false);

            return;
          }
        });
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
