import { Directive, ElementRef, HostListener } from '@angular/core';
import { ImageLoadingService } from './image-loading.service';

@Directive({
  selector: '[waitableImage]',
})
export class WaitableImageDirective {
  constructor(private el: ElementRef, private imageService: ImageLoadingService) {
    imageService.imageLoading(el.nativeElement);
  }

  @HostListener('load')
  onLoad() {
    this.imageService.imageLoadedOrError(this.el.nativeElement);
  }

  @HostListener('error')
  onError() {
    this.imageService.imageLoadedOrError(this.el.nativeElement);
  }
}
