import { EventEmitter, Injectable } from '@angular/core';
import { Toast } from './toast.interface';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  showToast: EventEmitter<Toast> = new EventEmitter();

  constructor() {}

  /**
   * Shows a toast with a generic error message
   */
  showRequestErrorToast() {
    this.showToast.emit({
      contentKey: 'error.requestError',
      code: 'backend.request-error',
      type: 'error',
    });
  }
}
