<div class="navigation-drawer-content-container d-flex flex-column">
  <div class="d-flex flex-inline navigation-items-container flex-wrap">
    <ng-container *ngFor="let item of navigationItems">

      <app-vst-navigation-item *ngIf="item.profileRequired == null || item.profileRequired === profile?.type"
        [item]="item"></app-vst-navigation-item>
    </ng-container>
  </div>
  <div data-bs-dismiss="offcanvas" *ngIf="profile != null" class="d-flex logout-span mt-4 align-items-center" (click)="
        requestLogout()"><i class="fas fa-sign-out-alt"></i>
    <span translate>navigation.logout</span>
  </div>
</div>
