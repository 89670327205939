import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '../navigation-item.interface';

@Component({
  selector: 'app-vst-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
})
export class NavigationItemComponent implements OnInit {
  @Input() item: NavigationItem | null = null;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  itemClicked() {
    this.router.navigateByUrl(this.item?.url!).then(() => {});
  }
}
