<section class="logbook-container d-flex">
  <div class="logbook-content">
    <div class="open-carousel-arrow-container" *ngIf="selectedTabKey === 'logbook-tab-canvas'"
      (click)="showCanvasItemsCarousel()">
      <i class="fas fa-angle-left"></i>
    </div>
    <ng-container [ngSwitch]="selectedTabKey">
      <ng-container *ngSwitchCase="'logbook-tab-answers'">
        <app-vst-answers *ngIf="currentExperience && showQuestionsTab">
        </app-vst-answers>
      </ng-container>

      <app-vst-canvas *ngSwitchCase="'logbook-tab-canvas'"></app-vst-canvas>

      <app-vst-writing *ngSwitchCase="'logbook-tab-writing'"></app-vst-writing>
    </ng-container>
  </div>
  <div class="tabs-container d-flex flex-column">
    <ng-container *ngFor="let item of logbookTabItems">
      <app-vst-logbook-tab *ngIf="item.target != 'logbook-tab-answers' || currentExperience != null && showQuestionsTab"
        [selected]="selectedTabKey === item.target" (click)="changeSelectedTab(item.target)" [item]="item">
      </app-vst-logbook-tab>
    </ng-container>

  </div>
</section>