import { Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { backend } from 'src/app/common/backend/backend';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import { DocumentWithMimeType } from 'src/app/common/interfaces/document-with-mime-type';
import Experience from 'src/app/common/models/Experience';
import Question from 'src/app/common/models/Question';
import { LogbookService } from 'src/app/logbook/logbook.service';
import { ResolvedExperienceWithEmbeddedResources } from './experience.resolver';

@Injectable({
  providedIn: 'root',
})
export class ExperienceService {
  currentExperience: BehaviorSubject<ResolvedExperienceWithEmbeddedResources | null> =
    new BehaviorSubject<ResolvedExperienceWithEmbeddedResources | null>(null);

  /**
   * Documents selected to give an answer
   */
  selectedDocuments: BehaviorSubject<DocumentWithMimeType[]> = new BehaviorSubject(
    [] as DocumentWithMimeType[],
  );
  /**
   * Marks if the experience's questions apply to all documents.
   */
  questionsApplyToAllDocuments: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private toastService: ToastService,
    private router: Router,
    private logbookService: LogbookService,
  ) {
    this.initializeBehavior();
  }

  initializeBehavior() {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.url.some((seg) => seg.path === 'experiencia')) {
          // We set the logbook's selected tab to the one used for responses

          this.logbookService.selectedTabKey.next('logbook-tab-answers');

          // If we're on some experience, we'll try to get the experience's questions

          backend
            .from<Experience & { questions: Question[] }>('experience')
            .select('*, questions:question!experience_fk(*)')
            //@ts-ignore
            .eq('id', event.snapshot.data.experience.id)
            .order('ordinal', {
              ascending: true,
              foreignTable: 'questions',
            })
            .then((response) => {
              if (response.error || response.body.length === 0) {
                this.toastService.showToast.emit({
                  type: 'error',
                  code: 'backend.logbook.errorFetchingQuestions',
                  contentKey: 'journey.errors.errorFetching',
                });

                this.router.navigateByUrl('/');
                this.resetExperienceContext();

                return;
              }

              this.questionsApplyToAllDocuments.next(
                response.body[0].questions_apply_to_all_documents,
              );

              if (response.body[0].questions_apply_to_all_documents == true) {
                this.selectedDocuments.next([]);
              }

              this.logbookService.relevantQuestions.next(response.body[0].questions);
            });
          return;
        }
      }

      if (event instanceof NavigationEnd) {
        if (!event.url.split('/').some((seg) => seg === 'experiencia')) {
          // If it's not an experience, we remove questions and
          // set the default tab to writing

          this.logbookService.selectedTabKey.next('logbook-tab-writing');

          this.resetExperienceContext();
        }
      }
    });
  }

  resetExperienceContext() {
    this.logbookService.relevantQuestions.next([]);
    this.currentExperience.next(null);
    this.selectedDocuments.next([]);
    this.questionsApplyToAllDocuments.next(false);
  }
}
