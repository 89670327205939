import { EventEmitter, Injectable } from '@angular/core';

export enum Modals {
  CONFIRM_ACTION,
}

export interface ModalOptions {
  type: Modals;
  data: any;
  callback: (...args: any[]) => any;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  /**
   * Shows a modal from the defined values.
   */
  showModal: EventEmitter<ModalOptions> = new EventEmitter();

  constructor() {}
}
