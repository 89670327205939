import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Session, User } from '@supabase/gotrue-js';
import { ErrorInterceptorSkipStatusHeader } from 'errorhandler.interceptor';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { backend } from '../backend/backend';
import { ToastService } from '../components/toast/toast.service';
import Profile from '../models/Profile';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  session: BehaviorSubject<Session | null> = new BehaviorSubject(backend.auth.session());
  user: BehaviorSubject<User | null> = new BehaviorSubject(backend.auth.user());
  profile: BehaviorSubject<Profile | null | any> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private toastService: ToastService,
    private httpClient: HttpClient,
  ) {
    this.initializeSubscriptions();
  }

  initializeSubscriptions() {
    backend.auth.onAuthStateChange((event, session) => {
      this.session.next(session);

      switch (event) {
        case 'SIGNED_IN':
        case 'USER_UPDATED': {
          this.user.next(backend.auth.user());

          this.tryFetchProfile();
          break;
        }

        case 'USER_DELETED': {
          if (session != null) {
            this.logout();
          }
          break;
        }
      }
    });
  }

  logout(redirect = true) {
    this.profile.next(null);
    backend.auth.signOut().then(() => {
      if (redirect) {
        this.router.navigateByUrl('/acceder/teacher');
      }
    });
  }

  tryFetchProfile() {
    return new Promise<void>((resolve, reject) => {
      backend
        .from('profile')
        .select('*')
        .eq('id_users', this.user.getValue()!.id)
        .then((response) => {
          if (response.error) {
            reject(response.error);
            console.error(response.error);
            this.logout(false);

            this.toastService.showToast.emit({
              contentKey: 'error.requestError',
              code: 'backend.auth.no-profile',
              type: 'error',
            });
            return;
          }

          resolve();
          this.profile.next(response.data[0]);
        });
    });
  }

  register(
    name: string,
    rut: string,
    email: string,
    institution: string,
    region: string,
    country: string,
    signupAnswer: string,
    password: string,
    redirectTo: string,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/v1/auth/register`,
      {
        name,
        rut,
        email,
        password,
        country,
        institution,
        region,
        signupAnswer,
        redirectTo,
      },
      {
        // We're expecting a 404 if the account exists

        headers: new HttpHeaders().set(ErrorInterceptorSkipStatusHeader, '409'),
      },
    );
  }
}
