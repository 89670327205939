import { Component, Input, OnInit } from '@angular/core';
import { LogbookTabItem } from '../logbook-tab-item.interface';

@Component({
  selector: 'app-vst-logbook-tab',
  templateUrl: './logbook-tab.component.html',
  styleUrls: ['./logbook-tab.component.scss'],
})
export class LogbookTabComponent implements OnInit {
  @Input() item: LogbookTabItem | null = null;
  @Input() selected = false;

  constructor() {}

  ngOnInit(): void {}
}
