import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PostgrestResponse } from '@supabase/postgrest-js';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../common/auth/auth.service';
import { backend } from '../common/backend/backend';
import Experience from '../common/models/Experience';
import Journey from '../common/models/Journey';
import ProfileFinishedExperience from '../common/models/ProfileFinishedExperience';
import ProfileFinishedJourney from '../common/models/ProfileFinishedJourney';
import ProfileFinishedStage from '../common/models/ProfileFinishedStage';
import Stage from '../common/models/Stage';
import { LogbookService } from '../logbook/logbook.service';

@Injectable({
  providedIn: 'root',
})
export class JourneyService {
  currentJourney: BehaviorSubject<Journey | null> = new BehaviorSubject<Journey | null>(null);

  constructor(
    private authService: AuthService,
    private router: Router,
    private logbookService: LogbookService,
  ) {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          if (!event.url.startsWith('/trayectos/') && !event.url.startsWith('/quiz')) {
            this.currentJourney.next(null);
            this.logbookService.showLogbookIcon.next(false);
          }
        }
      },
    });
  }

  setExperienceAsFinished(experience: Experience) {
    return new Promise<PostgrestResponse<ProfileFinishedExperience>>(async (resolve, reject) => {
      const setExperienceAsFinishedResponse = await backend
        .from<ProfileFinishedExperience>('profile_finished_experience')
        .insert({
          id_profile: this.authService.profile.getValue()!.id,
          id_experience: experience.id,
        });

      if (
        setExperienceAsFinishedResponse.error != null &&
        setExperienceAsFinishedResponse.error.code !== '23505' /** allow progress already saved */
      ) {
        return reject(setExperienceAsFinishedResponse.error);
      }

      resolve(setExperienceAsFinishedResponse);
    });
  }

  setStageAsFinished(stage: Stage) {
    return new Promise<PostgrestResponse<ProfileFinishedStage>>(async (resolve, reject) => {
      // We get the stage's journey, an see if this is the last one
      // We mark the journey as finished

      const journeyResponse = await backend
        .from<Journey & { stages: Stage[] }>('journey')
        .select('*, stages:stage!journey_fk(*)')
        .eq('id', stage.id_journey);

      if (journeyResponse.error != null) {
        return reject(journeyResponse.error);
      }

      const stageJourney = journeyResponse.body[0];

      const highestJourneyStagesOrdinal = Math.max(...stageJourney.stages.map((s) => s.ordinal), 1);

      if (stage.ordinal === highestJourneyStagesOrdinal) {
        // This would be the last stage, so we set the journey as finished
        try {
          await this.setJourneyAsFinished(stageJourney);
        } catch (error) {
          return reject(error);
        }
      }

      const setStageAsFinishedResponse = await backend
        .from<ProfileFinishedStage>('profile_finished_stage')
        .insert({
          id_profile: this.authService.profile.getValue()!.id,
          id_stage: stage.id,
        });

      if (
        setStageAsFinishedResponse.error != null &&
        setStageAsFinishedResponse.error.code !== '23505' /** allow progress already saved */
      ) {
        return reject(setStageAsFinishedResponse.error);
      }

      resolve(setStageAsFinishedResponse);
    });
  }

  setJourneyAsFinished(journey: Journey) {
    return new Promise<void>((resolve, reject) => {
      backend
        .from<ProfileFinishedJourney>('profile_finished_journey')
        .insert({
          id_journey: journey.id,
          id_profile: this.authService.profile.getValue()!.id,
        })
        .then((response) => {
          resolve();

          if (
            response.error != null &&
            response.error.code !== '23505' /** allow progress already saved */
          ) {
            return reject(response.error);
          }
        });
    });
  }
}
