import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { ResolvedExperienceWithEmbeddedResources } from 'src/app/journey/stage/experience/experience.resolver';
import { QuizWithStagesAndEmbeddedResources } from 'src/app/quiz/quiz.resolver';
import Profile from '../models/Profile';

type AnswerDraftRecord = {
  dictionary: AnswerDraftDictionary;
  storedAt: dayjs.Dayjs;
};

type AnswerDraftDictionary = { [questionKey: string]: string };

export function getExperienceDraftKey(
  profile: Profile,
  experience: ResolvedExperienceWithEmbeddedResources,
) {
  return `${profile.id}/${experience.stage.journey.id}/${experience.stage.id}/${experience.id}`;
}

export function getQuizDraftKey(profile: Profile, experience: QuizWithStagesAndEmbeddedResources) {
  return `quiz/${profile.id}/${experience.id}`;
}

@Injectable({
  providedIn: `root`,
})
export class AnswerLocalDraftsService {
  private LOCAL_STORAGE_PREFIX = `drafts/`;

  constructor() {}

  keyExists(key: string) {
    return this.readKey(key) != null;
  }

  writeToKey(key: string, dictionary: AnswerDraftDictionary) {
    localStorage.setItem(
      this.LOCAL_STORAGE_PREFIX + key,
      JSON.stringify({
        dictionary,
        storedAt: dayjs().utc(),
      }),
    );
  }

  readKey(key: string): AnswerDraftRecord | null {
    const record = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_PREFIX + key) ?? `null`);

    if (record == null) {
      return null;
    }

    return {
      dictionary: record.dictionary,
      storedAt: dayjs(record.storedAt).utc(),
    };
  }

  removeKey(key: string) {
    localStorage.removeItem(this.LOCAL_STORAGE_PREFIX + key);
  }
}
