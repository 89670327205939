import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import Stage from 'src/app/common/models/Stage';

@Injectable({
  providedIn: 'root',
})
export class StageService {
  currentStage: BehaviorSubject<Stage | null> = new BehaviorSubject<Stage | null>(null);

  constructor(private router: Router) {
    // Subscribe to router events. If /etapa/ is not present in the URL
    // then there is no etapa

    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          if (!event.url.includes('/etapa/')) {
            this.currentStage.next(null);
          }
        }
      },
    });
  }
}
