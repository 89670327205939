import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  backendUrl: 'https://backend.viajeporlasvoces.cl',
  siteUrl: 'https://viajeporlasvoces.cl',
  publicAnonKey:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTYwMzk2ODgzNCwicm9sZSI6ImFub24ifQ.ANLK1wifQ-4PNS4FfqoGiYJY-o_z7oUc_UyC_j1eLUw',
  apiUrl: 'https://backend.viajeporlasvoces.cl/node/api',
  corsProxyUrl: 'https://backend.viajeporlasvoces.cl/cors-proxy/',
  loggerLevel: NgxLoggerLevel.OFF,
};
