import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResolvedExperienceWithEmbeddedResources } from '../journey/stage/experience/experience.resolver';
import { ExperienceService } from '../journey/stage/experience/experience.service';
import { CanvasService } from './canvas/canvas.service';
import { logbookTabItems } from './logbook-tab-items';
import { LogbookService } from './logbook.service';

@Component({
  selector: 'app-vst-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.scss'],
})
export class LogbookComponent implements OnInit, OnDestroy {
  logbookTabItems = logbookTabItems;

  selectedTabKey: string = 'logbook-tab-canvas';

  unsubscribeAll: Subject<void> = new Subject();

  currentExperience?: ResolvedExperienceWithEmbeddedResources | null;

  showQuestionsTab = false;

  constructor(
    private logbookService: LogbookService,
    private experienceService: ExperienceService,
    private canvasService: CanvasService,
  ) {
    this.initializeSubscriptions();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  initializeSubscriptions() {
    this.logbookService.selectedTabKey.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (key) => {
        this.selectedTabKey = key;
      },
    });

    this.experienceService.currentExperience.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (experience) => {
        this.currentExperience = experience;
      },
    });

    this.logbookService.relevantQuestions.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (questions) => {
        this.showQuestionsTab = questions != null && questions.length > 0;
      },
    });
  }

  changeSelectedTab(key: string) {
    this.logbookService.selectedTabKey.next(key);
  }

  showCanvasItemsCarousel() {
    this.canvasService.showingItemCarousel.next(true);
  }
}
