import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageLoadingService } from '../common/image-loading.service';

@Component({
  templateUrl: './journey-end.component.html',
  styleUrls: ['./journey-end.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state(
        '*',
        style({
          opacity: 0,
        }),
      ),
      state(
        'ready',
        style({
          opacity: 1,
        }),
      ),
      transition('* <=> ready', animate('{{duration}}ms {{delay}}ms ease-in'), {
        params: {
          duration: 300,
          delay: 0,
        },
      }),
    ]),
  ],
})
export class JourneyEndComponent implements OnInit {
  // No hay forma que typescript reconozca las definiciones de tipo para
  // resize-observer-browser. Usamos ts-ignore.
  //@ts-ignore
  monedaCoverResizerObserver: ResizeObserver | null = null;

  @ViewChild('monedaCoverImage', {
    read: ElementRef,
    static: false,
  })
  monedaCoverImage: ElementRef | null = null;

  monedaCoverImagePositionAndSize: {
    width: number;
    height: number;
    offsetTop: number;
    offsetBottom: number;
  } | null = null;

  scrHeight: number | null = window.innerHeight;
  scrWidth: number | null = window.innerWidth;

  imagesState = 'void';

  unsubscribeAll$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(private imageService: ImageLoadingService, private ngZone: NgZone) {
    this.imageService.imagesLoading$.pipe(takeUntil(this.unsubscribeAll$)).subscribe({
      next: (number) => {
        setTimeout(() => {
          this.imagesState = number == 0 ? 'ready' : 'void';
        });
      },
    });
  }

  ngOnInit(): void {}

  ngAfterContentChecked() {
    if (this.monedaCoverImage != null) {
      if (this.monedaCoverResizerObserver) {
        this.monedaCoverResizerObserver.disconnect();
      }

      //@ts-ignore
      this.monedaCoverResizerObserver = new ResizeObserver((entries) => {
        const monedaCoverEntry = entries[0];

        setTimeout(() => {
          this.ngZone.run(() => {
            if (monedaCoverEntry != null && this.monedaCoverImage != null) {
              this.monedaCoverImagePositionAndSize = {
                height: monedaCoverEntry.contentRect.height,
                width: monedaCoverEntry.contentRect.width,
                offsetTop: this.monedaCoverImage.nativeElement.offsetTop,
                offsetBottom:
                  this.monedaCoverImage.nativeElement.offsetTop +
                  monedaCoverEntry.contentRect.height,
              };
            }
          });
        }, 0);
      });

      this.monedaCoverResizerObserver.observe(this.monedaCoverImage.nativeElement);
    }
  }

  ngOnDestroy() {
    this.monedaCoverResizerObserver?.disconnect();
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
}
