<div class="favourites-carousel-container d-flex flex-column w-100 h-100 align-items-center">
  <div class=" spinner spinner-border text-primary" role="status" *ngIf="showFetchingIndicator">
    <span class="sr-only">Loading...</span>
  </div>

  <div translate class="muted favorites-carousel-header mt-1 mb-3">logbook.canvas.itemsCarousel.responsesHeader</div>
  <div *ngFor="let answer of answersAndQuestions" class="answer-card card-body d-flex flex-column">
    <strong>{{answer.question.content}}</strong>

    <div>
      {{answer.answer_text}}
    </div>

    <div (click)="addAnswerToCanvas(answer)" class=" add-to-canvas-button">
      <i class="mt-1 text-primary fas fa-arrow-circle-left"></i>
    </div>

  </div>

</div>