import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PostgrestResponse } from '@supabase/postgrest-js';
import { isString } from 'is-what';
import { Subject, Subscription, combineLatest, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { backend } from 'src/app/common/backend/backend';
import { SpinnerButtonComponent } from 'src/app/common/components/spinner-button/spinner-button.component';
import { ToastService } from 'src/app/common/components/toast/toast.service';
import { DocumentWithMimeType } from 'src/app/common/interfaces/document-with-mime-type';
import Answer from 'src/app/common/models/Answer';
import Experience from 'src/app/common/models/Experience';
import Question from 'src/app/common/models/Question';
import Stage from 'src/app/common/models/Stage';
import { JourneyService } from 'src/app/journey/journey.service';
import { ResolvedExperienceWithEmbeddedResources } from 'src/app/journey/stage/experience/experience.resolver';
import { ExperienceService } from 'src/app/journey/stage/experience/experience.service';
import { StageService } from 'src/app/journey/stage/stage.service';
import { LogbookService } from '../logbook.service';
import {
  AnswerLocalDraftsService,
  getExperienceDraftKey,
} from 'src/app/common/self-contained/answer-local-drafts.service';
import { getLatestTimestampForAnswerArray } from 'src/app/common/utils/answer';

export interface AnswerWithDocument extends Answer {
  document: DocumentWithMimeType;
}

@Component({
  selector: 'app-vst-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.scss'],
})
export class AnswersComponent implements OnInit {
  unsubscribeAll: Subject<void> = new Subject();

  questions?: Question[];

  currentExperience: ResolvedExperienceWithEmbeddedResources | null = null;

  selectedDocuments: DocumentWithMimeType[] = [];

  answersFormGroup: FormGroup | null = null;

  /**
   * Marks if the experience's questions apply to all documents.
   */
  questionsApplyToAllDocuments = false;

  savingAnswer = false;

  currentStage: Stage | null = null;

  triggerAnswerDrafts: EventEmitter<string> = new EventEmitter();

  constructor(
    private logbookService: LogbookService,
    private experienceService: ExperienceService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private journeyService: JourneyService,
    private stageService: StageService,
    private answerLocalDraftsService: AnswerLocalDraftsService,
  ) {
    this.initializeSubscriptions();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  async restoreAnswersFromDraftOrDatabase(
    questions: Question[],
    experience: ResolvedExperienceWithEmbeddedResources,
  ) {
    /**
     * Answers in the database.
     */
    const storedAnswersResponse = await backend
      .from<AnswerWithDocument>('answer')
      .select('*, document:document!document_fk(*, document_mime_type)')
      .in(
        'id_question',
        questions!.map((q) => q.id),
      )
      .eq('id_profile', this.authService.profile.getValue().id);

    if (storedAnswersResponse.error) {
      this.logbookService.showingLogbook.next(false);

      this.toastService.showToast.emit({
        contentKey: 'error.requestError',
        code: 'backend.request-error',
        type: 'error',
      });
      return;
    }

    const storedAnswers = storedAnswersResponse.body;

    const latestTimestampForAnswers = getLatestTimestampForAnswerArray(storedAnswers);

    //! If you change something here, be sure that you change the logic for selectedDocuments in experience.resolver

    const possibleDraft = this.answerLocalDraftsService.readKey(
      getExperienceDraftKey(this.authService.profile.getValue(), experience),
    );

    // Out of the drafts and stored answers, we keep the latest
    if (
      possibleDraft != null &&
      possibleDraft.dictionary != null &&
      (latestTimestampForAnswers == null ||
        possibleDraft.storedAt.isAfter(latestTimestampForAnswers))
    ) {
      this.answersFormGroup!.patchValue(possibleDraft.dictionary, {
        emitEvent: false,
      });
    } else {
      this.answersFormGroup!.patchValue(
        storedAnswers.reduce((acc, curr) => {
          if (curr.document != null) {
            return {
              ...acc,
              [curr.document.id]: {
                ...(acc[curr.document.id] != null && acc[curr.document.id]),
                [curr.id_question]: curr.answer_text,
              },
            };
          }

          return {
            ...acc,
            [curr.id_question]: curr.answer_text,
          };
        }, {}),
        {
          emitEvent: false,
        },
      );
    }
  }

  initializeSubscriptions() {
    combineLatest([
      // This combined observable won't be triggered before the experience resolver is done
      this.logbookService.relevantQuestions.pipe(takeUntil(this.unsubscribeAll)),
      this.experienceService.currentExperience.pipe(takeUntil(this.unsubscribeAll)),
      this.experienceService.questionsApplyToAllDocuments.pipe(takeUntil(this.unsubscribeAll)),
      this.experienceService.selectedDocuments.pipe(takeUntil(this.unsubscribeAll)),
    ])
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: ([questions, experience, questionsApplyToAllDocuments, selectedDocuments]) => {
          this.questions = questions;
          this.currentExperience = experience;
          this.questionsApplyToAllDocuments = questionsApplyToAllDocuments;
          this.selectedDocuments = selectedDocuments;

          this.generateFormGroup();

          if (
            questions == null ||
            questions.length === 0 ||
            experience == null ||
            questionsApplyToAllDocuments == null
          ) {
            return;
          }

          this.restoreAnswersFromDraftOrDatabase(questions, experience);
        },
      });

    this.triggerAnswerDrafts.pipe(takeUntil(this.unsubscribeAll), debounceTime(500)).subscribe({
      next: async () => {
        if (!this.answersFormGroup || !this.questions || this.currentExperience == null) {
          return;
        }

        // Store them onto drafts

        this.answerLocalDraftsService.writeToKey(
          getExperienceDraftKey(this.authService.profile.getValue(), this.currentExperience),
          {
            ...this.answersFormGroup.value,
          },
        );
      },
    });

    this.stageService.currentStage.pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (stage) => {
        this.currentStage = stage;
      },
    });
  }

  generateFormGroup() {
    if (!this.questions) {
      return;
    }

    const valueSnapshot = { ...(this.answersFormGroup?.value || {}) };

    if (this.questionsApplyToAllDocuments) {
      this.answersFormGroup = this.formBuilder.group(
        this.questions!.reduce((qAcc, qCurr) => {
          if (qCurr.is_general) {
            return qAcc;
          }

          Object.assign(qAcc, {
            [qCurr.id]: ['', Validators.required],
          });

          return qAcc;
        }, {}),
      );
    } else {
      this.answersFormGroup = this.formBuilder.group(
        this.selectedDocuments.reduce((acc, curr) => {
          Object.assign(acc, {
            [curr.id]: this.formBuilder.group(
              this.questions!.reduce((qAcc, qCurr) => {
                if (qCurr.is_general) {
                  return qAcc;
                }
                Object.assign(qAcc, {
                  [qCurr.id]: ['', Validators.required],
                });

                return qAcc;
              }, {}),
            ),
          });

          return acc;
        }, {}),
      );
    }

    // general questions

    this.questions!.filter((q) => q.is_general).forEach((q) => {
      this.answersFormGroup!.addControl(q.id, this.formBuilder.control('', Validators.required));
    });

    this.answersFormGroup!.patchValue(valueSnapshot);
  }

  async saveAnswers(
    buttonRef: SpinnerButtonComponent | null = null,
    goToNext: boolean = false,
    advanceExperienceProgress = true,
  ) {
    if (!this.answersFormGroup || !this.questions) {
      return;
    }

    if (buttonRef) {
      buttonRef.loading = true;
    }

    try {
      const documentFormGroupValues: { [key: string]: { [key: string]: any } } = {
        ...this.answersFormGroup.value,
      };

      let response: PostgrestResponse<any> | null = null;

      if (this.questionsApplyToAllDocuments) {
        // general questions will be included

        response = await backend.rpc('upsert_answers', {
          _id_profile: this.authService.profile.getValue()!.id,
          _answers: documentFormGroupValues,
        });
      } else {
        const generalAnswers: { [key: string]: { [key: string]: any } } = {};

        Object.keys(documentFormGroupValues).forEach((fgk) => {
          // if the formgroup value contains just a string,
          // then it's a general answer. we'll collect them and insert them separately

          if (isString(documentFormGroupValues[fgk])) {
            generalAnswers[fgk] = documentFormGroupValues[fgk];

            delete documentFormGroupValues[fgk];
          }
        });

        const generalAnswersResponse = (response = await backend.rpc('upsert_answers', {
          _id_profile: this.authService.profile.getValue()!.id,
          _answers: generalAnswers,
        }));

        if (generalAnswersResponse.error) {
          throw response!.error;
        }

        response = await backend.rpc('upsert_answers_and_documents', {
          _id_profile: this.authService.profile.getValue()!.id,
          _answers_per_document: documentFormGroupValues,
        });
      }

      if (response!.error) {
        throw response!.error;
      }

      if (response == null || response.body == null) {
        this.toastService.showToast.emit({
          contentKey: 'logbook.errors.errorSavingAnswers',
          type: 'error',
        });

        if (buttonRef) {
          buttonRef.loading = false;
        }

        return;
      }
    } catch (error) {
      this.toastService.showToast.emit({
        contentKey: 'logbook.errors.errorSavingAnswers',
        type: 'error',
      });

      if (buttonRef) {
        buttonRef.loading = false;
      }
      return;
    }

    if (buttonRef) {
      buttonRef.loading = false;
    }

    const experience: ResolvedExperienceWithEmbeddedResources =
      this.experienceService.currentExperience.getValue() as any;

    this.answerLocalDraftsService.removeKey(
      getExperienceDraftKey(this.authService.profile.getValue().id, experience),
    );

    if (advanceExperienceProgress) {
      try {
        await this.journeyService.setExperienceAsFinished(experience);
      } catch (error) {
        console.error(error);
        this.toastService.showToast.emit({
          contentKey: 'error.errorSavingProgress',
          type: 'error',
        });
        return;
      }

      if (goToNext) {
        let urlToNavigate: string | null = null;

        const nextExperienceExists = experience.stage.experiences.some(
          (e) => e.ordinal === experience!.ordinal + 1,
        );

        if (experience.stage.is_sequential === true) {
          if (!nextExperienceExists) {
            // If there's no next experience, we redirect to the journey site and save progress, or the exit quiz

            if (experience.stage.exit_quiz != null) {
              urlToNavigate = `/quiz/${experience.stage.id_exit_quiz}`;
            } else {
              urlToNavigate = `/trayectos/${experience.stage.journey.codename}`;

              try {
                await this.journeyService.setStageAsFinished(experience.stage);
              } catch (error) {
                console.error(error);
                this.toastService.showToast.emit({
                  contentKey: 'error.errorSavingProgress',
                  type: 'error',
                });
                return;
              }
            }
          } else {
            urlToNavigate = `/trayectos/${experience.stage.journey.codename}/etapa/${
              experience.stage.ordinal
            }/experiencia/${experience.ordinal + 1}`;
          }
        } else {
          // If it's not sequential, we redirect to the stage's root url
          urlToNavigate = `/trayectos/${experience.stage.journey.codename}/etapa/${experience.stage.ordinal}`;
        }

        this.logbookService.showingLogbook.next(false);

        this.router.navigateByUrl(urlToNavigate);
      }
    }
  }

  onTextAreaChange() {
    this.triggerAnswerDrafts.emit();
  }
}
