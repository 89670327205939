import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from 'auth.interceptor';
import { ErrorHandlerInterceptor } from 'errorhandler.interceptor';
import { LoggerModule } from 'ngx-logger';
import { QuillModule } from 'ngx-quill';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackendTokenInterceptor } from './backend-token.interceptor';
import { AuthService } from './common/auth/auth.service';
import { GlobalErrorHandler } from './common/error-handling';
import { HeaderComponent } from './common/header/header.component';
import { NavigationDrawerContentComponent } from './common/navigation/navigation-drawer-content/navigation-drawer-content.component';
import { NavigationItemComponent } from './common/navigation/navigation-item/navigation-item.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { SharedModule } from './common/shared.module';
import { ExperienceService } from './journey/stage/experience/experience.service';
import { AnswersComponent } from './logbook/answers/answers.component';
import { AnswersItemCarouselComponent } from './logbook/canvas/canvas-item-carousel/answers-item-carousel/answers-item-carousel.component';
import { CanvasItemCarouselComponent } from './logbook/canvas/canvas-item-carousel/canvas-item-carousel.component';
import { DocumentFavouritesCarouselComponent } from './logbook/canvas/canvas-item-carousel/document-favourites-carousel/document-favourites-carousel.component';
import { CanvasComponent } from './logbook/canvas/canvas.component';
import { LogbookTabComponent } from './logbook/logbook-tab/logbook-tab.component';
import { LogbookComponent } from './logbook/logbook.component';
import { WritingComponent } from './logbook/writing/writing.component';
import { LayoutModule } from '@angular/cdk/layout';
import { JourneyEndComponent } from './journey-end/journey-end.component';
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper/core';
import * as Sentry from '@sentry/angular';

SwiperCore.use([Navigation, EffectCoverflow]);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LogbookComponent,
    LogbookTabComponent,
    AnswersComponent,
    CanvasComponent,
    CanvasItemCarouselComponent,
    DocumentFavouritesCarouselComponent,
    AnswersItemCarouselComponent,
    WritingComponent,
    HeaderComponent,
    NavigationComponent,
    NavigationItemComponent,
    NavigationDrawerContentComponent,
    JourneyEndComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    HttpClientModule,
    LayoutModule,
    LoggerModule.forRoot({
      level: environment.loggerLevel,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'v73',
    }),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          ['clean'], // remove formatting button

          ['image'], // link and image, video
        ],
      },
    }),

    SharedModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory:
        (authService: AuthService, experienceService: ExperienceService, router: Router) =>
        async () => {
          if (authService.user.getValue() != null) {
            await authService.tryFetchProfile();
          }
        },
      deps: [AuthService, ExperienceService, Router],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
