import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { GridsterModule } from 'angular-gridster2';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NestedEllipsisModule } from 'ngx-nested-ellipsis';
import { SwiperModule } from 'swiper/angular';
import { AssistantComponent } from '../assistant/assistant.component';
import { DocumentCarouselComponent } from '../journey/common/document-carousel/document-carousel.component';
import { DocumentInfoFooterComponent } from '../journey/common/document-carousel/document-info-footer/document-info-footer.component';
import { CanvasContentComponent } from '../logbook/canvas/canvas-content/canvas-content.component';
import { ConfirmActionModalComponent } from './components/confirm-action-modal/confirm-action-modal.component';
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { ToastComponent } from './components/toast/toast.component';
import { CorsProxyPipe } from './cors-proxy.pipe';
import { DocumentAudioPlayerComponent } from './document-audio-player/document-audio-player.component';
import { DocumentFullscreenViewerComponent } from './document-fullscreen-viewer/document-fullscreen-viewer.component';
import { DocumentImageViewerComponent } from './document-image-viewer/document-image-viewer.component';
import { DocumentPdfViewerComponent } from './document-pdf-viewer/document-pdf-viewer.component';
import { DocumentVideoPlayerComponent } from './document-video-player/document-video-player.component';
import { FlowerFooterPaddingComponent } from './flower-footer-padding/flower-footer-padding.component';
import { FlowersFooterComponent } from './flowers-footer/flowers-footer.component';
import { IsDocumentInArrayPipe } from './is-document-in-array.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SolidFooterComponent } from './solid-footer/solid-footer.component';
import { WaitableImageDirective } from './waitable-image.directive';
import { GetValueFromMapPipe } from './get-value-from-map.pipe';
import { ToDayJsAndFormatPipe } from './to-day-js-and-format.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { JoinNonNullValuesPipe } from './join-non-null-values.pipe';

@NgModule({
  declarations: [
    SpinnerButtonComponent,
    ToastComponent,
    DocumentVideoPlayerComponent,
    DocumentImageViewerComponent,
    AssistantComponent,
    ConfirmActionModalComponent,
    IsDocumentInArrayPipe,
    DocumentPdfViewerComponent,
    SafeHtmlPipe,
    DocumentAudioPlayerComponent,
    CorsProxyPipe,
    CanvasContentComponent,
    WaitableImageDirective,
    SolidFooterComponent,
    FlowersFooterComponent,
    FlowerFooterPaddingComponent,
    DocumentFullscreenViewerComponent,
    DocumentCarouselComponent,
    DocumentInfoFooterComponent,
    GetValueFromMapPipe,
    ToDayJsAndFormatPipe,
    JoinNonNullValuesPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      extend: true,
    }),
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NestedEllipsisModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
    GridsterModule,
    SwiperModule,
    PdfViewerModule,
    NgSelectModule,
  ],
  exports: [
    SwiperModule,
    TranslateModule,
    ReactiveFormsModule,
    SpinnerButtonComponent,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DocumentVideoPlayerComponent,
    DocumentImageViewerComponent,
    AssistantComponent,
    NestedEllipsisModule,
    FormsModule,
    ConfirmActionModalComponent,
    IsDocumentInArrayPipe,
    DocumentPdfViewerComponent,
    SafeHtmlPipe,
    DocumentAudioPlayerComponent,
    CorsProxyPipe,
    CanvasContentComponent,
    WaitableImageDirective,
    SolidFooterComponent,
    FlowersFooterComponent,
    NgxExtendedPdfViewerModule,
    PdfViewerModule,
    FlowerFooterPaddingComponent,
    DocumentFullscreenViewerComponent,
    DocumentCarouselComponent,
    GetValueFromMapPipe,
    ToDayJsAndFormatPipe,
    NgSelectModule,
    JoinNonNullValuesPipe,
  ],
})
export class SharedModule {}
