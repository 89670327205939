import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoSessionGuard } from './common/auth/no-session.guard';
import { JourneyEndComponent } from './journey-end/journey-end.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'registro',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'acceder',
    canActivate: [NoSessionGuard],
    canLoad: [NoSessionGuard],
    canActivateChild: [NoSessionGuard],
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'biblioteca',
    loadChildren: () => import('./library/library.module').then((m) => m.LibraryModule),
  },
  {
    path: 'trayectos',
    loadChildren: () => import('./journey/journey.module').then((m) => m.JourneyModule),
  },
  {
    path: 'estudiantes',
    loadChildren: () => import('./student/student.module').then((m) => m.StudentModule),
  },
  {
    path: 'investigadores',
    loadChildren: () => import('./researcher/researcher.module').then((m) => m.ResearcherModule),
  },
  {
    path: 'profesores',
    loadChildren: () => import('./teacher/teacher.module').then((m) => m.TeacherModule),
  },
  { path: 'quiz', loadChildren: () => import('./quiz/quiz.module').then((m) => m.QuizModule) },
  {
    path: 'recuperar-password',
    loadChildren: () =>
      import('./recuperar-password/recuperar-password.module').then(
        (m) => m.RecuperarPasswordModule,
      ),
  },
  {
    path: 'crear-nueva-password',
    loadChildren: () =>
      import('./crear-nueva-password/crear-nueva-password.module').then(
        (m) => m.CrearNuevaPasswordModule,
      ),
  },
  {
    path: 'ver-lienzo',
    loadChildren: () => import('./view-canvas/view-canvas.module').then((m) => m.ViewCanvasModule),
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./information-pages/information-pages.module').then((m) => m.InformationPagesModule),
  },
  {
    path: 'fin-trayecto',
    component: JourneyEndComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: !environment.production,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
