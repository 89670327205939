import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  inititateLandingAnimation: EventEmitter<void> = new EventEmitter();
  initiateLandingAnimationTransition: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
