import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { ToastService } from 'src/app/common/components/toast/toast.service';

export const ErrorInterceptorSkipStatusHeader = 'X-Skip-Status-Error-Interceptor';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let skippedStatuses: any[] = [];

    if (request.headers.has(ErrorInterceptorSkipStatusHeader)) {
      skippedStatuses = request.headers
        .get(ErrorInterceptorSkipStatusHeader)!
        .split(';')
        .map((s) => parseInt(s));
    }

    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (skippedStatuses.includes(err.status)) {
            return;
          }

          console.error(err);

          if (err.status === 401) {
            this.toastService.showToast.emit({
              contentKey: 'error.unauthorized',
              code: 'backend.unauthorized',
              type: 'error',
            });

            return;
          }

          this.toastService.showToast.emit({
            contentKey: 'error.requestError',
            code: 'backend.request-error',
            type: 'error',
          });
        },
      }),
    );
  }
}
