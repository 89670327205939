import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastService } from './components/toast/toast.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private toastService: ToastService, private injector: Injector) {}

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      // Http errors are taken care of by the interceptor

      return;
    }

    console.error(error);

    this.toastService.showToast.emit({
      contentKey: 'error.generalError',
      type: 'error',
    });
  }
}
