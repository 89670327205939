import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/common/auth/auth.service';
import { DocumentWithMimeType } from 'src/app/common/interfaces/document-with-mime-type';
import { AnswerAndQuestion } from './canvas-item-carousel/answers-item-carousel/answers-item-carousel.component';

@Injectable({
  providedIn: 'root',
})
export class CanvasService {
  addNewDocumentToCanvas: EventEmitter<DocumentWithMimeType> = new EventEmitter();

  addNewAnswerCardToCanvas: EventEmitter<AnswerAndQuestion> = new EventEmitter();

  showingItemCarousel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(authService: AuthService) {
    // since this service gets instantiated before NgxWebStorage sets up the prefix
    // we use the browser API and add the prefix

    const storedValue = localStorage.getItem('v73|canvas-item-carousel-active') === 'true';

    authService.profile.pipe(skipWhile((v) => v == null)).subscribe({
      next: (profile) => {
        if (profile == null) {
          this.showingItemCarousel.next(false);
          return;
        }

        this.showingItemCarousel.next(storedValue == null ? false : storedValue);

        this.initializeSubscriptions();
      },
    });
  }

  initializeSubscriptions() {
    this.showingItemCarousel.subscribe((showing) => {
      localStorage.setItem('v73|canvas-item-carousel-active', showing.toString());
    });
  }
}
